import canAutoplay from 'can-autoplay';
import * as THREE from 'three';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass.js';
import EffectComposer from './vendor/postprocessing/EffectComposer';
import BadTVShader from './vendor/shaders/BadTVShader';
import RGBShiftShader from './vendor/shaders/RGBShiftShader';
import FilmShader from './vendor/shaders/FilmShader';
import StaticShader from './vendor/shaders/StaticShader';
import CopyShader from './vendor/shaders/CopyShader';

let camera, scene, renderer;
let videoTexture, videoMaterial;
let composer;
let shaderTime = 0;
let badTVParams, badTVPass;
let staticParams, staticPass;
let rgbParams, rgbPass;
let filmParams, filmPass;
let renderPass, copyPass;

let video = document.createElement('video');
let videoIsPlaying = false;
let videos = [], videoIndex = 0;
let started = false;


init();
animate();

function init() {

    camera = new THREE.PerspectiveCamera(30, 960 / 540, 1, 1000);
    camera.position.z = 1000;
    scene = new THREE.Scene();
    scene.add(camera);

    for (let i = 1; i < 112; i++) {
        videos.push('SGP' + pad(i, 3));
    }

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    shuffle(videos);

    let staticAudio = document.getElementById('static');
    staticAudio.volume = 0.5;

    //Load Video
    video = document.createElement('video');
    video.setAttribute('preload', 'auto');
    video.setAttribute('autoplay', true);
    video.setAttribute('playsinline', true);
    video.addEventListener('ended', nextVideo, false);
    video.addEventListener('canplaythrough', () => {
        // console.log(" loaded successfully", video);
        replaceVideoPlane(video);
    }, false);

    video.addEventListener('error', () => {
        // console.log("error");
        nextVideo();
    }, false);

    let startButton = document.getElementById('startButton');

    canAutoplay.audio().then(({result}) => {
        if (result === true) {
            start();
        } else {
            startButton.style.display = 'block';
        }
    });

    document.querySelector('.monitor').addEventListener('click', () => {
        if(started) {
            nextVideo();
        } else {
            start();
        }
    });

    function start() {
        startButton.remove();
        staticAudio.play();
        nextVideo();
        started = true;
    }


    staticAudio.addEventListener('ended', function () {
        document.getElementById('static').play();
    });

    document.addEventListener('keydown', function (evt) {
        if (evt.code === 'Space') {
            nextVideo();
        }
    });

    function shuffle(a) {
        let j, x, i;
        for (i = a.length; i; i -= 1) {
            j = Math.floor(Math.random() * i);
            x = a[i - 1];
            a[i - 1] = a[j];
            a[j] = x;
        }
    }

    function nextVideo() {

        video.crossOrigin = '';
        video.crossOrigin = 'anonymous';

        // if(video.hasAttribute("crossOrigin")) {
        video.src = 'https://singapouring.sos-ch-gva-2.exo.io/videos/' + videos[videoIndex] + '.mp4';
        // } else {
        //     video.src = '/mp4/' + videos[videoIndex] + '.mp4';
        // }
        video.load();

        // console.log(videos[videoIndex]);

        document.querySelector('#cam').textContent = 'CAM ' + videos[videoIndex].replace('SGP', '');

        videoIndex++;
        if (videoIndex > videos.length - 1) {
            shuffle(videos);
            videoIndex = 0;
        }
    }

    function replaceVideoPlane(myVideo) {
        video.pause();
        video = myVideo;

        var selectedObject = scene.getObjectByName('video_plane');
        scene.remove(selectedObject);

        //init video texture
        videoTexture = new THREE.Texture(video);
        videoTexture.minFilter = THREE.LinearFilter;
        videoTexture.magFilter = THREE.LinearFilter;

        videoMaterial = new THREE.MeshBasicMaterial({
            map: videoTexture,
        });
        //Add video plane
        var planeGeometry = new THREE.PlaneGeometry(960, 540, 1, 1);
        var plane = new THREE.Mesh(planeGeometry, videoMaterial);
        plane.name = 'video_plane';
        scene.add(plane);
        plane.z = 0;
        // plane.scale.x = plane.scale.y = 1.2;

        // console.log(videoIndex);

        video.play();
    }

    //init video texture
    videoTexture = new THREE.Texture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;

    videoMaterial = new THREE.MeshBasicMaterial({
        map: videoTexture,
    });
    //Add video plane
    var planeGeometry = new THREE.PlaneGeometry(960, 540, 1, 1);
    var plane = new THREE.Mesh(planeGeometry, videoMaterial);
    plane.name = 'video_plane';
    // plane.z = -1000;
    scene.add(plane);
    // plane.scale.x = plane.scale.y = 1.2;


    //init renderer
    renderer = new THREE.WebGLRenderer();
    // renderer.setSize(960, 540);
    renderer.domElement.className = 'screen';

    document.querySelector('.monitor').appendChild(renderer.domElement);


    // reframe('canvas');

    //POST PROCESSING
    //Create Shader Passes
    renderPass = new RenderPass(scene, camera);
    badTVPass = new ShaderPass(BadTVShader);
    rgbPass = new ShaderPass(RGBShiftShader);
    filmPass = new ShaderPass(FilmShader);
    staticPass = new ShaderPass(StaticShader);
    copyPass = new ShaderPass(CopyShader);

    //set shader uniforms
    filmPass.uniforms['grayscale'].value = 0;

    //Init DAT GUI control panele
    badTVParams = {
        mute: false,
        show: false,
        distortion: 1,
        distortion2: 0.3,
        speed: 0.1,
        rollSpeed: 0,
    };

    staticParams = {
        show: true,
        amount: 0.4,
        size2: 4.0,
    };

    rgbParams = {
        show: false,
        amount: 0.005,
        angle: 0.0,
    };

    filmParams = {
        show: true,
        count: 600,
        sIntensity: 0.3,
        nIntensity: 0.3,
    };


    onToggleShaders();
    onToggleMute();
    onParamsChange();


    // window.addEventListener('resize', onResize, false);
    // onResize();

    var full = document.querySelector('#rec-full');
    var empty = document.querySelector('#rec-empty');
    var isFull = true;

    setInterval(function () {
        if (isFull) {
            full.classList.add('is-hidden');
            empty.classList.remove('is-hidden');
        } else {
            full.classList.remove('is-hidden');
            empty.classList.add('is-hidden');
        }
        isFull = !isFull;
    }, 1000);
}

function onParamsChange() {
    //copy gui params into shader uniforms
    badTVPass.uniforms['distortion'].value = badTVParams.distortion;
    badTVPass.uniforms['distortion2'].value = badTVParams.distortion2;
    badTVPass.uniforms['speed'].value = badTVParams.speed;
    badTVPass.uniforms['rollSpeed'].value = badTVParams.rollSpeed;

    staticPass.uniforms['amount'].value = staticParams.amount;
    staticPass.uniforms['size'].value = staticParams.size2;

    rgbPass.uniforms['angle'].value = rgbParams.angle * Math.PI;
    rgbPass.uniforms['amount'].value = rgbParams.amount;

    filmPass.uniforms['sCount'].value = filmParams.count;
    filmPass.uniforms['sIntensity'].value = filmParams.sIntensity;
    filmPass.uniforms['nIntensity'].value = filmParams.nIntensity;
}

function onToggleMute() {
    video.volume = badTVParams.mute ? 0 : 1;
}

function onToggleShaders() {

    //Add Shader Passes to Composer
    //order is important
    composer = new EffectComposer(renderer);
    composer.addPass(renderPass);

    if (filmParams.show) {
        composer.addPass(filmPass);
    }

    if (badTVParams.show) {
        composer.addPass(badTVPass);
    }

    if (rgbParams.show) {
        composer.addPass(rgbPass);
    }

    if (staticParams.show) {
        composer.addPass(staticPass);
    }

    composer.addPass(copyPass);
    copyPass.renderToScreen = true;
}

function animate() {

    shaderTime += 0.1;
    badTVPass.uniforms['time'].value = shaderTime;
    filmPass.uniforms['time'].value = shaderTime;
    staticPass.uniforms['time'].value = shaderTime;

    //console.log(video.paused)

    if (video.readyState === video.HAVE_ENOUGH_DATA && !video.paused) {
        if (videoTexture) videoTexture.needsUpdate = true;
        if (!videoIsPlaying) {
            document.getElementById('static').pause();
            videoIsPlaying = true;
            videoMaterial.visible = true;
            staticParams.amount = 0.1;
            staticParams.size = 1.7;
            onParamsChange();
        }
    } else {
        if (videoIsPlaying) {
            document.getElementById('static').play();
            videoIsPlaying = false;
            videoMaterial.visible = false;
            staticParams.amount = 0.4;
            staticParams.size = 4.0;
            onParamsChange();
        }
    }

    requestAnimationFrame(animate);
    composer.render(1);
    //renderer.render( scene, camera );
}

// function onResize() {
// renderer.setSize(window.innerWidth, window.innerHeight);
// camera.aspect = window.innerWidth / window.innerHeight;
// camera.updateProjectionMatrix();
// }
